import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Info from '../Info/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { gsap } from 'gsap';
import BackgroundSlider from './BackgroundSlider';

const Home = ({ scrollToSectionRef }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
    const iconRef = useRef(null);
    const prestationsRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (scrollToSectionRef) {
            scrollToSectionRef.current = () => {
                prestationsRef.current?.scrollIntoView({ behavior: 'smooth' });
            };
        }
    }, [scrollToSectionRef]);

    useEffect(() => {
        gsap.to(iconRef.current, {
            y: 20,
            repeat: -1,
            yoyo: true,
            ease: "power1.inOut",
            duration: 1.5,
        });

        const handleResize = () => {
            setIsMobile(window.innerWidth < 950);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className='home'>
                <BackgroundSlider />

                <div className='container-text'>
                    <h2>POUR CHAQUE PROJET,<br />UNE SOLUTION BERI MONTAGE</h2>
                    {isMobile ? (
                        <p>
                            Beri Montage SARL est une entreprise familiale spécialisée dans le montage, la fourniture et la pose pour vos projets de construction et de rénovation. Notre équipe expérimentée s'engage à fournir des solutions de qualité, adaptées à vos besoins, tout en garantissant votre satisfaction à chaque étape du projet. Faites confiance à Beri Montage pour être votre partenaire dans la réalisation de vos travaux.
                        </p>
                    ) : (
                        <p>
                            Beri Montage SARL est une entreprise familiale spécialisée dans le montage, la fourniture et la pose pour vos projets <br /> de construction et de rénovation. Notre équipe expérimentée s'engage à fournir des solutions de qualité, adaptées <br />à vos besoins, tout en garantissant votre satisfaction à chaque étape du projet. Faites confiance à Beri Montage <br /> pour être votre partenaire dans la réalisation de vos travaux.
                        </p>
                    )}
                    <button onClick={() => navigate('/propos')}>À propos de nous</button>
                </div>

                {!isMobile && (
                    <FontAwesomeIcon
                        ref={iconRef}
                        style={{
                            position: 'absolute',
                            bottom: '40px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            color: '#fff',
                            fontSize: '45px'
                        }}
                        icon={faAnglesDown}
                    />
                )}
            </div>

            <Info prestationsRef={prestationsRef} />
        </>
    );
};

export default Home;
