import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom';
import LogoWhite from '../Images/LogoWhite.svg';
import './Footer.css'; 

function Footer() {
    return (
        <footer>
            <div className='container-footer'>

                <div className='footer-top'>
                    <img src={LogoWhite} alt="Logo Beri Montage Sàrl" />
                </div>

                <div className='footer-center'>

                    <div className='link'>
                        <h2>NAVIGATION</h2>
                        <ul>
                            <li>
                                <Link to="/">Accueil</Link>
                            </li>
                            <li>
                                <Link to="/propos">À propos de nous</Link>
                            </li>
                        </ul>
                    </div>

                    <div className='services'>
                        <h2>NOS PRESTATIONS</h2>
                        <ul>
                            <li>Con. Métallique et Bois</li>
                            <li>Ventilation</li>
                            <li>Fenêtre PVC</li>
                            <li>Serrurie</li>
                            <li>Porte</li>
                            <li>Garde corps</li>
                        </ul>
                    </div>

                    <div className='social'>
                        <h2>NOS RÉSEAUX</h2>
                        <ul>
                            <li>
                                <a 
                                    href="https://www.facebook.com/people/Beri-Montage-S%C3%A0rl/100091830105562/" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <span><FontAwesomeIcon icon={faFacebook} /></span> Facebook
                                </a>
                            </li>
                            <li>
                                <a 
                                    href="https://www.instagram.com/beri_montage_sarl/" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <span><FontAwesomeIcon icon={faInstagram} /></span> Instagram
                                </a>
                            </li>
                            <li>
                                <a 
                                    href="https://ch.linkedin.com/in/beri-montage-s%C3%A0rl-25aa72299" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <span><FontAwesomeIcon icon={faLinkedin} /></span> Linkedin
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='coord'>
                        <h2>NOUS CONTACTEZ</h2>
                        <ul>
                            <li>
                                <span><FontAwesomeIcon icon={faLocationDot} /></span> 
                                <a href="https://www.google.com/maps/place/Ch.+du+Petit-Flon+26a,+1052+Le+Mont-sur-Lausanne,+Suisse" target="_blank" rel="noopener noreferrer">
                                    Ch. du Petit-Flon 26a<br />1052 Le Mont-sur-Lausanne, Suisse
                                </a>

                            </li>
                            <li>
                                <span><FontAwesomeIcon icon={faPhone} /></span> 
                                <a href="tel:+41216474575">+41 21 647 45 75</a>
                            </li>
                            <li>
                                <span><FontAwesomeIcon icon={faEnvelope} /></span> 
                                <a href="mailto:info@berimontage.ch">info@berimontage.ch</a>
                            </li>
                        </ul>
                    </div>
                    
                </div>

                <div className='footer-bottom'>
                    <h3>© 2024 tout droit réservé à Beri Montage Sàrl.</h3>
                </div>
                
            </div>
        </footer>
    );
}

export default Footer;
