import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './DialogServices.css';

const DialogServices = ({ title, description, img, onClose }) => {
    const dialogVariants = {
        open: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeOut" }
        },
        closed: {
            opacity: 0,
            scale: 0.9,
            y: 50,
            transition: { duration: 0.5, ease: "easeIn" }
        }
    };

    const backdropVariants = {
        open: { opacity: 1, transition: { duration: 0.3 } },
        closed: { opacity: 0, transition: { duration: 0.3 } }
    };

    return (
        <motion.div
            className="services-dialog"
            initial="closed"
            animate="open"
            exit="closed"
            variants={backdropVariants}
            onClick={onClose}
        >
            <motion.div
                className="dialog-content"
                onClick={(e) => e.stopPropagation()}
                initial="closed"
                animate="open"
                exit="closed"
                variants={dialogVariants}
            >
                <button className="close-dialog" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <div className="container-img">
                    <img src={img} alt={`Icon du service ${title}`} />
                </div>
                <div className="container-text">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default DialogServices;
