import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; 
import Header from './Beri/Header/Header';
import Footer from './Beri/Footer/Footer';
import Home from './Beri/Home/Home';
import About from './Beri/About/About';
import Loading from './Beri/Loading/Loading';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => setIsLoading(false), 1000);
        return () => clearTimeout(timer);
    }, [location.pathname]);

    return (
        <>
            {isLoading && <Loading />}
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/propos" element={<About />} />
            </Routes>
            <Footer />
        </>
    );
};

export default App;
