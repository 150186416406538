import React from 'react';
import ContactIMG from '../Images/Contact.svg';
import './Contact.css';

const Contact = () => {
    return (
        <>
            <div className="contact">
                <div className='wrapper'>
                    <div className='container-img'>
                        <img src={ContactIMG} alt="Maison" />
                    </div>
                    <div className='container-form'>
                        <h3>Contactez-nous</h3>
                        <div className='container-coord'>
                            <div className='input-box'>
                                <label htmlFor="">Nom</label>
                                <input type="text" name="" id="" />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="">Prénom</label>
                                <input type="text" name="" id="" />
                            </div>
                        </div>
                        <div className='container-coord'>
                            <div className='input-box'>
                                <label htmlFor="">E-mail</label>
                                <input type="text" name="" id="" />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="">Numéro de téléphone</label>
                                <input type="text" name="" id="" />
                            </div>
                        </div>
                        <div className='input-box-object'>
                            <label htmlFor="">Votre message</label>
                            <textarea name="" id=""></textarea>
                        </div>
                        <div className='container-btn'>
                            <button>Envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
