import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; 
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import Project_1 from '../Images/Project/Project_1.webp';
import Project_2 from '../Images/Project/Project_2.webp';
import Project_3 from '../Images/Project/Project_3.webp';
import Project_4 from '../Images/Project/Project_4.webp';
import Project_5 from '../Images/Project/Project_5.webp';
import './Projects.css';

const Projects = () => {

    const boxes_projects = [
        {
            title: 'Aéroport de genève',
            description: 'Const. métallique, Serrurie',
            img: Project_1,
        },
        {
            title: 'Collège les Vergers',
            description: 'Const. métallique/bois et Serrurie',
            img: Project_2,
        },
        {
            title: 'Suva',
            description: 'Const. métallique/bois et Fenêtre',
            img: Project_3,
        },
        {
            title: 'Hotel Ibis',
            description: 'Const. métallique, Serrurie et Porte',
            img: Project_4,
        },
        {
            title: 'Bâtiment habitable',
            description: 'Serrurie, Ventilation, Porte et Fenêtre',
            img: Project_5,
        },
    ];

    return (
        <>
            <div className="projects">
                <div className='container-title'>
                    <h1>Nos réalisations</h1>
                </div>
                <div className='container-box-projects'>
                    <Swiper
                        spaceBetween={20} 
                        slidesPerView={3}
                        autoplay={{
                            delay: 4000, 
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Pagination, Navigation, Autoplay]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20, 
                            },
                            950: {
                                slidesPerView: 2,
                                spaceBetween: 10, 
                            },
                            1240: {
                                slidesPerView: 2,
                                spaceBetween: 10, 
                            },
                            1350: {
                                slidesPerView: 3,
                                spaceBetween: 30, 
                            }
                        }}
                    >

                        {boxes_projects.map((box, index) => (
                            <SwiperSlide key={index}>
                                <div className="box">
                                    <div className="container-img">
                                        <img src={box.img} alt={box.title} />
                                    </div>
                                    <div className='container-info'>
                                        <h2>{box.title}</h2>
                                        <p>{box.description}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default Projects;
