import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import HommeAvatar from '../Images/Homme.svg';
import FemmeAvatar from '../Images/Femme.svg';
import Background from '../Images/Background_About.svg';
import './About.css';

const About = () => {

    const teamMembers = [
        {
            name: 'Shqipe Berisha',
            role: 'Directrice générale',
            img: FemmeAvatar,
        },
        {
            name: 'Dugagjin Berisha',
            role: 'Directeur travaux',
            img: HommeAvatar,
        },
        {
            name: 'Leart Berisha',
            role: 'Responsable ventilation et communication',
            img: HommeAvatar,
        },
    ];

    return (
        <div className="about">
            <img className="background" src={Background} alt="Fond à propos" />
            <div className="wrapper">
                <div className="container-info">
                    <h2>Notre histoire</h2>
                    <p>
                    Fondée le 30 août 2022, Beri Montage Sàrl est une entreprise de sous-traitance spécialisée dans le montage, la construction métallique, la ventilation, la serrurerie, ainsi que dans la fourniture et pose de divers équipements. Forte d’une équipe qualifiée et d’un contrôle rigoureux à chaque étape, notre société garantit savoir-faire, précision et qualité d’exécution pour tous vos projets, qu’il s’agisse de nouvelles constructions ou de rénovations. Notre engagement envers la satisfaction de nos clients nous pousse à répondre avec professionnalisme et soin aux exigences spécifiques de chaque réalisation.
                    </p>
                </div>
                <div className="container-staff">
                    <h2>Notre équipe</h2>
                    <div className='container-box'>
                        <Swiper
                            spaceBetween={20} 
                            slidesPerView={3}
                            autoplay={{
                                delay: 4000, 
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Pagination, Autoplay]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                },
                                1000: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            {teamMembers.map((member, index) => (
                                <SwiperSlide key={index}>
                                    <div className="box">
                                        <div className="container-img">
                                            <img src={member.img} alt={member.name} />
                                        </div>
                                        <h3>{member.name}</h3>
                                        <p>{member.role}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                
                <div className='container-collaborators'>
                    <h2>Nos collaborateurs</h2>
                    <div className='container-box'>
                        <div className='box'>
                            <div className='container-img'> 
                                <img src={HommeAvatar} alt="Stéphane Faraone" />
                            </div>
                            <h3>Stéphane Faraone</h3>
                            <p>Expert en ventilation collaborant avec Beri Montage SARL, il s'occupe des études techniques pour tous les projets de ventilation grâce à son expérience et son expertise. Il dirige également sa propre entreprise, CRF Énergie, spécialisée dans ce domaine.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
