import React, { useState } from 'react';
import { Blockquote } from 'flowbite-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; 
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { AnimatePresence } from 'framer-motion';

import Construction_Icon from '../Images/Services_icon/Construction.svg';
import Ventilation_Icon from '../Images/Services_icon/Ventilation.svg';
import Fenetre_Icon from '../Images/Services_icon/Fenetre.svg';
import Serrurie_Icon from '../Images/Services_icon/Serrurie.svg';
import Porte_Icon from '../Images/Services_icon/Porte.svg';
import Garde_Corps_Icon from '../Images/Services_icon/Garde_Corps.svg';

import Construction from '../Images/Services/Bois.webp';
import Ventilation from '../Images/Services/Ventilation.webp';
import Fenetre from '../Images/Services/Fenetre.webp';
import Serrurie from '../Images/Services/Serrurie.webp';
import Porte from '../Images/Services/Porte.webp';
import Garde_Corps from '../Images/Services/Garde_Corps.webp';

import Bernard_Nicod from '../Images/Client/Bernard_Nicod.svg';
import HRS from '../Images/Client/HRS.svg';
import Ville_Lausanne from '../Images/Client/Ville_Lausanne.svg';
import Erne from '../Images/Client/Erne.svg';

import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import DialogServices from '../DialogServices/DialogServices';
import './Info.css';

const Info = () => {
    const [isHoveredSanitaire, setIsHoveredSanitaire] = useState(false);
    const [isHoveredChauffage, setIsHoveredChauffage] = useState(false);
    const [isHoveredDepannage, setIsHoveredDepannage] = useState(false);
    const [dialogData, setDialogData] = useState({ isOpen: false, title: '', description: '', img: '' });

    const boxes_services = [
        {
            title: 'Con. Métallique et Bois',
            description: 'En tant que sous-traitant, nous intervenons dans la réalisation de travaux de montage en construction métallique et bois, en mettant à profit notre expertise technique pour assurer la création de structures solides, durables et parfaitement fonctionnelles, tout en respectant rigoureusement les normes de sécurité et en apportant une attention particulière à l’esthétique et aux spécificités de chaque projet.',
            icon: Construction_Icon,
            img: Construction,
            hoverState: [isHoveredSanitaire, setIsHoveredSanitaire]
        },
        {
            title: 'Ventilation',
            description: 'En tant que sous-traitant spécialisé, nous proposons des services de montage de systèmes de ventilation alliant expertise, performance et fiabilité. Nous veillons à ce que chaque installation soit réalisée dans le strict respect des normes en vigueur et des exigences spécifiques de chaque projet, afin de garantir une qualité d’air optimale et un fonctionnement durable des équipements.',
            icon: Ventilation_Icon,
            img: Ventilation,
            hoverState: [isHoveredChauffage, setIsHoveredChauffage]
        },
        {
            title: 'Fenêtre PVC',
            description: 'Nous proposons une large gamme de fenêtres en PVC, réputées pour leurs performances exceptionnelles en matière d\'isolation thermique et acoustique. De la phase de sélection des modèles à leur installation finale, nous nous engageons à fournir un service de qualité supérieure, alliant expertise, durabilité et esthétique pour répondre à vos besoins spécifiques.',
            icon: Fenetre_Icon,
            img: Fenetre,
            hoverState: [isHoveredDepannage, setIsHoveredDepannage]
        },
        {
            title: 'Serrurie',
            description: 'Nous offrons des services de serrurerie hautement spécialisés, conçus pour renforcer la sécurité de vos espaces. Qu\'il s\'agisse de l\'installation de serrures robustes ou de la conception et de la mise en place de systèmes de sécurité sur mesure, nous vous proposons des solutions fiables, innovantes et adaptées à vos besoins spécifiques, afin de garantir votre tranquillité d\'esprit.',
            icon: Serrurie_Icon,
            img: Serrurie,
            hoverState: [isHoveredSanitaire, setIsHoveredSanitaire]
        },
        {
            title: 'Porte',
            description: 'Notre expertise englobe la fourniture et l\'installation de portes modernes, robustes et esthétiques, soigneusement conçues pour s\'adapter à une grande variété de styles architecturaux tout en répondant aux standards les plus élevés en matière de sécurité et de durabilité, avec une attention particulière portée à chaque détail afin de satisfaire pleinement vos attentes et exigences spécifiques.',
            icon: Porte_Icon,
            img: Porte,
            hoverState: [isHoveredChauffage, setIsHoveredChauffage]
        },
        {
            title: 'Garde Corps',
            description: 'Nous concevons et installons des garde-corps sur mesure, alliant sécurité, durabilité et esthétique. Que ce soit pour des balcons, escaliers, terrasses ou toute autre application, nos solutions personnalisées sont pensées pour répondre à vos exigences spécifiques, en harmonisant fonctionnalité et design, tout en respectant les normes de sécurité en vigueur.',
            icon: Garde_Corps_Icon,
            img: Garde_Corps,
            hoverState: [isHoveredDepannage, setIsHoveredDepannage]
        }
    ];

    const boxes_clients = [
        {
            title: 'Bernard Nicod',
            img: Bernard_Nicod,
        },
        {
            title: 'HRS Real Estate SA',
            img: HRS,
        },
        {
            title: 'Ville de Lausanne',
            img: Ville_Lausanne,
        },
        {
            title: 'Erne AG',
            img: Erne,
        }
    ];

    const openDialog = (title, description, img) => {
        setDialogData({ isOpen: true, title, description, img });
    };

    const closeDialog = () => {
        setDialogData({ isOpen: false, title: '', description: '', img: '' });
    };

    return (
        <>
            <div className="info">
                <div className="container-box-services"> 
                    <Swiper
                        spaceBetween={20} 
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 4000, 
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Pagination, Navigation, Autoplay]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20, 
                            },
                            860: {
                                slidesPerView: 2,
                                spaceBetween: 30, 
                            },
                            1240: {
                                slidesPerView: 3,
                                spaceBetween: 20, 
                            },
                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 20, 
                            }
                        }}
                    >

                        {boxes_services.map((box, index) => (
                            <SwiperSlide key={index}>
                                <div className="box">
                                    <div className="container-img">
                                        <img src={box.icon} alt={box.title} />
                                    </div>
                                    <div className="container-title">
                                        <h4>{box.title}</h4>
                                    </div>
                                    <div className="container-button">
                                        <button
                                            onMouseEnter={() => box.hoverState[1](true)}
                                            onMouseLeave={() => box.hoverState[1](false)}
                                            onClick={() => openDialog(box.title, box.description, box.img)}
                                        >
                                            Découvrir
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="container-text">
                    <div className="text">
                        <Blockquote style={{ backgroundColor: '#0D486A', borderRadius: '8px', padding: '20px', fontSize: '1.25rem', lineHeight: '1.75rem' }}>
                            <svg
                                className="mb-4 h-12 w-12 text-stone-400 dark:text-stone-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 18 14"
                            >
                                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                            </svg>
                            <p>
                            "Chez <span style={{fontWeight: '600'}}>Beri Montage Sàrl</span>, entreprise familiale de sous-traitance, nous mettons toute notre passion et notre savoir-faire au service de vos projets. Du métal au bois, de la ventilation à la serrurerie, nous créons des ouvrages alliant solidité et élégance. Avec nous, chaque réalisation devient un gage de qualité et de durabilité."</p>
                        </Blockquote>
                    </div>
                </div>

                <Projects/>

                <div className='background-box-clients'>
                    <div className='container-title'>
                        <h1>Ils nous ont fait confiance</h1>
                    </div>
                    <div className="container-box-clients"> 
                        <Swiper
                            spaceBetween={20} 
                            slidesPerView={3}
                            autoplay={{
                                delay: 4000, 
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Pagination, Navigation, Autoplay]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 20, 
                                },
                                860: {
                                    slidesPerView: 2,
                                    spaceBetween: 30, 
                                },
                                1050: {
                                    slidesPerView: 3,
                                    spaceBetween: 30, 
                                },
                                1350: {
                                    slidesPerView: 4,
                                    spaceBetween: 30, 
                                },
                            }}
                        >

                            {boxes_clients.map((box, index) => (
                                <SwiperSlide key={index}>
                                    <div className="box">
                                        <div className="container-img">
                                            <img src={box.img} alt={box.title} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            <Contact />

            <AnimatePresence>
                {dialogData.isOpen && (
                    <DialogServices
                        title={dialogData.title}
                        description={dialogData.description}
                        img={dialogData.img}
                        onClose={closeDialog}
                        isOpen={dialogData.isOpen}
                    />
                )}
            </AnimatePresence>


        </>
    );
};

export default Info;
