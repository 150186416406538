import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';
import LogoWhite from '../Images/LogoWhite.svg';
import LogoBlack from '../Images/LogoBlack.svg';
import './Header.css';

const Header = () => {
    const location = useLocation();

    const isProposPage = location.pathname === '/propos';

    return (
        <header className="header">
            <Link className='container-logo' to="/">
                <img 
                    className="logo-header" 
                    src={isProposPage ? LogoBlack : LogoWhite} 
                    alt="Logo" 
                />
            </Link>
            <nav>
                <ul className="menu">
                    <li className={isProposPage ? 'active' : ''}>
                        <Link to="/" style={{ color: isProposPage ? 'black' : 'white' }}>Accueil</Link>
                    </li>
                    <li className={isProposPage ? 'active' : ''}>
                        <Link to="/propos" style={{ color: isProposPage ? 'black' : 'white' }}>À propos de nous</Link>
                    </li>
                </ul>
            </nav>

            {isProposPage && (
                <Link to="/" className="home-icon">
                    <FontAwesomeIcon icon={faHouseChimney} />
                </Link>
            )}
        </header>
    );
};

export default Header;
