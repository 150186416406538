import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Background from '../Images/Background_Home1.webp';
import Background2 from '../Images/Background_Home2.webp';
import Background3 from '../Images/Background_Home3.webp';
import Background4 from '../Images/Background_Home4.webp';
import Background5 from '../Images/Background_Home5.webp';

const BackgroundSlider = () => {
    const images = [Background, Background2, Background3, Background4, Background5];
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images.length]); 

    return (
        <div className='container-background'>
            <AnimatePresence>
                <motion.img
                    key={currentImage}
                    className='background'
                    src={images[currentImage]}
                    alt="Fond"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                />
            </AnimatePresence>
        </div>
    );
};

export default BackgroundSlider;
